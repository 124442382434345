import jwtDecode from "jwt-decode"
import { Preferences } from "@capacitor/preferences"
export const isBrowser = () => typeof window !== "undefined"

export const getUser = async () => {
  const { value } = await Preferences.get({ key: "token" })
  if (isBrowser() && value) {
    return jwtDecode(value)
  }
  return {}
}

export const getJWT = async () => {
  const { value } = await Preferences.get({ key: "token" })
  if (isBrowser() && value) {
    if (value !== undefined) {
      return value
    }
  }
  return ""
}

export async function isLoggedIn() {
  const { value } = await Preferences.get({ key: "token" })

  if (value !== "" && value != null) {
    let expDate = new Date() / 10000
    try {
      expDate = JSON.parse(atob(value.split(".")[1])).exp
    } catch (e) {
      return false
    }
    return expDate > Math.floor(Date.now() / 1000)
  }
  return false
}

export const logout = async callback => {
  await Preferences.remove({ key: "token" })
  callback()
}

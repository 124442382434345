import React from "react"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { getJWT, isLoggedIn, logout } from "../services/auth/auth"
import fetch from "isomorphic-fetch"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import Wrapper from "../components/wrapper"

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (await isLoggedIn()) {
    const token = await getJWT()
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }
  }
  return {}
})

const defaultOptions = {
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutation: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("graphQLErrors", graphQLErrors)
  }
  if (networkError) {
    console.log("networkError", networkError)
    if (networkError.message.includes("401")) {
      logout(() => navigate("/"))
    }
  }
})

const link = ApolloLink.from([
  errorLink,
  authLink.concat(
    createUploadLink({
      uri: process.env.GATSBY_DYNAMIC_GRAPHQL,
      fetch,
    }),
  ),
])

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions,
})

export const wrapRootElement = ({ element, props }) => {
  return (
    <ApolloProvider client={client} {...props}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Helmet>
      {process.env.GATSBY_BROWSER_BUILD ? (
        <Wrapper>{element}</Wrapper>
      ) : (
        element
      )}
    </ApolloProvider>
  )
}
